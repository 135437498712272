<template>
  <div>
    <div class="title-tabs">Message Response Label</div>
    <div class="bg-white p-3">
      <div class="col-6 pl-0 mb-3">
        <InputSelectLanguage ref="inputLang" @changeLang="changeLanguage" />
      </div>

      <div class="col-6 pl-0 mb-3">
        <InputText placeholder="Search Label , Variable" v-model="search" />
      </div>
      <template v-if="isLoading">
        <OtherLoading />
      </template>
      <template v-else>
        <b-row>
          <template v-for="group in labelGroup">
            <div
              class="col-12 text-bold f-18"
              :key="'group' + group.display_label_name"
            >
              {{ group.display_label_name }}
            </div>

            <div
              class="col-md-6 col-12 d-flex align-items-center"
              v-for="items of group.items"
              :key="'label' + items.id"
            >
              <InputText
                :textFloat="items.display_label_name || items.label_name"
                :placeholder="items.label_name"
                type="text"
                :name="'input' + items.label_name"
                class="w-100"
                v-model="items.value"
                @input="updateField(items.id, $event)"
                :maxLength="400"
              />
            </div>
            <hr class="col-12" :key="'break' + group.display_label_name" />
          </template>
        </b-row>
      </template>
    </div>
    <FooterAction routePath="/setting" @submit="saveForm()" />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputSelectLanguage from "@/components/inputs/InputSelectLanguage";
export default {
  components: {
    OtherLoading,
    InputSelectLanguage,
  },

  data() {
    return {
      labelList: [],
      tabIndex: 0,
      languageList: [],
      selectLanguage: 0,
      isLoading: true,
      isFetch: false,
      search: "",
    };
  },
  computed: {
    labelGroup() {
      let grouped = this.groupByLabelDisplayGroupId(
        this.labelList[this.selectLanguage].label
      );

      // ถ้ามีค่าค้นหาให้กรองข้อมูล
      if (this.search.trim() !== "") {
        let searchTerm = this.search.toLowerCase();
        grouped = Object.fromEntries(
          Object.entries(grouped)
            .map(([groupId, group]) => {
              let filteredItems = group.items.filter(
                (item) =>
                  item.value.toLowerCase().includes(searchTerm) ||
                  item.label_name.toLowerCase().includes(searchTerm)
              );

              return filteredItems.length > 0
                ? [groupId, { ...group, items: filteredItems }]
                : null;
            })
            .filter(Boolean) // ลบค่า null ออก
        );
      }

      return grouped;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    groupByLabelDisplayGroupId(data) {
      return data.reduce((acc, item) => {
        const groupId = item.label_display_group_id;
        const displayName = item.group_name; // ใช้ชื่อกลุ่มจาก display_label_name

        if (!acc[groupId]) {
          acc[groupId] = {
            display_label_name: displayName,
            items: [],
          };
        }

        acc[groupId].items.push(item);
        return acc;
      }, {});
    },
    async getList(flag = false) {
      if (this.labelList.length == 0 || flag) {
        this.isLoading = true;
        const resp = await this.axios(
          `/setting/display_language/label_internal`
        );

        this.labelList = resp.data.detail;

        // this.labelGroup = this.groupByLabelDisplayGroupId(
        //   this.labelList[this.selectLanguage].label
        // );

        this.isFetch = true;
        await this.$nextTick();
        this.changeLanguage(1);
        this.isLoading = false;
      }
    },
    changeLanguage(val) {
      let index = this.labelList.findIndex((el) => el.language_id == val);
      this.selectLanguage = index;
    },
    async save() {
      let payload = this.labelList.map((el) => {
        return { ...el, label: el.label.filter((el) => el.is_update == 1) };
      });
      if (payload.some((el) => el.label.length > 0)) {
        const resp = await this.axios.post(
          "setting/display_language/label",
          payload
        );
        return resp;
      }
      return true;
    },
    async translate(text) {
      let res = await this.$Axios.post(
        `https://translation.googleapis.com/language/translate/v2?key=AIzaSyDDKKh7XRhb4jtuLPc4Op3lSJVjWcIUvEE`,
        { q: text, target: "tr" }
      );
      let translation = res.data.data.translations[0].translatedText;
      return translation;
    },

    updateField(id, value) {
      let index = this.labelList[this.selectLanguage].label.findIndex(
        (el) => el.id == id
      );

      this.labelList[this.selectLanguage].label[index].is_update = 1;
    },
    async saveForm() {
      this.$bus.$emit("showLoading");
      const res = await this.axios.post(
        "setting/display_language/label_internal",
        this.labelList
      );
      this.$bus.$emit("hideLoading");
      if (res.data.result)
        this.successAlert().then(() => this.$router.push("/setting"));
      else this.errorAlert(res.data.message);
    },
  },
};
</script>

<style></style>
